
.canvas_container {
    
    display: flex;
}

.canvas {
    width: 50%;
    border: 2px solid black;
    border-radius: 5px;
    margin-bottom: 100px;
}

.slider_container{
    width: 50%;
}
.slider{
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px;
    height: 40px;
}
.slider_title{
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 10px;
    width: 60px;
}

.function_container{
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: 48px;
    margin-bottom: 20px;
}
.object{
    background-color: rgb(255, 255, 123);
}

.function{
    background-color: rgba(243, 134, 243, 0.899);
}

.parameters{
    background-color: rgb(74, 159, 233);
}
.color_label{
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}
.color_input{
    width: 20%;
    height: 40px;
    margin: auto;
}