html{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 450;

}
.code_block{
    padding: 10px;
    border: 1px solid black;
    border-radius: 3px;
    background-color: rgba(243, 134, 243, 0.7);
    font-size: 50px;
    width: 100%;
    max-width: fit-content;
    white-space: initial;
    height: fit-content;
    margin: auto;
    margin-bottom: 3%;
    overflow-wrap: break-word;
   
}
.func{
    min-width: fit-content;
}

.run_button{
    height: 30px;
    width: 30%;
    margin-left: 30px;
    vertical-align: middle;
    border-radius: 3px;
    background-color: rgb(208, 237, 166); 
    font-size: 24px;
    
}
.param{
    font-size: large;
    vertical-align: middle;
    font-size: 32px;
    width: 150px;
}

.func_subheading{
    font-size: 32px;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 500;
    width: 20%;
}

.subheading{
    text-align : center; 
    font-size : 32px; 
    font-weight : 500;
}

h3{
    text-align: center;
    font-size: 32px;
}
.function_block{
    display: flex;

    align-items: center;
    justify-content: space-evenly;
}